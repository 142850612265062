import React, { useState, useEffect, createContext, useContext, useCallback } from "react";
import {
  Catalog,
  Section, Category, Product, SelectionGroup,
  OutOfStockList
} from "src/storefront/types/catalog";
import { DropdownButton } from "../components/dropdown_button";
import { parseCatalog, parseOutOfStockList } from "src/storefront/decoders";
import { classNames } from "src/common_util";
import { Temporal } from "@js-temporal/polyfill";
import { ReactComponent as HoursIcon } from "images/icons/time-clock-circle.svg";
import { OutOfStockDialog } from "./out_of_stock_dialog";

interface IConfigContext {
  outOfStockList: OutOfStockList;
  catalogUrl: string;
  storeId: string;
  updateStockUrl: string;
  manualStockManagement: boolean;
}

const ConfigContext = createContext<IConfigContext>({
  outOfStockList: new OutOfStockList({ loadedAt: Temporal.Now.instant(), outOfStockListItems: [] }),
  catalogUrl: "",
  storeId: "",
  updateStockUrl: "",
  manualStockManagement: false,
});

const Viewer = ({
  catalogUrl,
  outOfStockListUrl,
  editorUrl,
  storeId,
  updateStockUrl,
  manualStockManagement,
}: {
  catalogUrl?: string;
  outOfStockListUrl?: string;
  editorUrl?: string;
  storeId: string;
  updateStockUrl: string;
  manualStockManagement: boolean;
}) => {
  const [catalog, setCatalog] = useState<Catalog | undefined>();
  const [outOfStockList, setOutOfStockList] = useState<OutOfStockList | undefined>();
  const [fetchingCatalog, setFetchingCatalog] = useState<boolean>(true);
  const [fetchingOutOfStockList, setFetchingOutOfStockList] = useState<boolean>(true);

  useEffect(() => {
    if (catalogUrl) {
      fetch(catalogUrl).then(async resp => {
        const json = await resp.json();
        const catalog = parseCatalog(json);
        setCatalog(catalog);
      }).finally(() => setFetchingCatalog(false));
    } else {
      setFetchingCatalog(false);
    }

    if (outOfStockListUrl && outOfStockListUrl.length > 0) {
      fetch(outOfStockListUrl).then(async resp => {
        setOutOfStockList(parseOutOfStockList(Temporal.Now.instant(), await resp.json()));
      }).finally(() => setFetchingOutOfStockList(false));
    } else {
      setOutOfStockList(new OutOfStockList({ loadedAt: Temporal.Now.instant(), outOfStockListItems: [] }));
      setFetchingOutOfStockList(false);
    }
  }, [catalogUrl, outOfStockListUrl]);

  if (fetchingCatalog || fetchingOutOfStockList) {
    return <></>;
  }

  if (catalog && outOfStockList && storeId) {
    return (
      <ViewerInner
        catalogUrl={catalogUrl ?? ""}
        catalog={catalog}
        outOfStockList={outOfStockList}
        setOutOfStockList={setOutOfStockList}
        editorUrl={editorUrl}
        storeId={storeId}
        updateStockUrl={updateStockUrl}
        manualStockManagement={manualStockManagement}
      />
    );
  }

  return (
    <LaunchEditorButton buttonText={"Catalog editor"} editorUrl={editorUrl} />
  );
}

const ViewerInner = ({
  catalogUrl,
  catalog,
  outOfStockList,
  setOutOfStockList,
  editorUrl,
  storeId,
  updateStockUrl,
  manualStockManagement,
}: {
  catalogUrl: string;
  catalog: Catalog;
  outOfStockList: OutOfStockList;
  setOutOfStockList: React.Dispatch<React.SetStateAction<OutOfStockList | undefined>>;
  editorUrl?: string;
  storeId: string;
  updateStockUrl: string;
  manualStockManagement: boolean;
}) => {
  const sections = catalog.sections.map(section => {
    return <CatalogSection section={section} key={section.key} setOutOfStockList={setOutOfStockList} />
  });

  return (
    <ConfigContext.Provider value={{ catalogUrl: catalogUrl, outOfStockList: outOfStockList, storeId: storeId, updateStockUrl: updateStockUrl, manualStockManagement: manualStockManagement }}>
      <LaunchEditorButton buttonText={"Edit"} editorUrl={editorUrl} />
      {sections}
    </ConfigContext.Provider>
  );
}

const LaunchEditorButton = ({ buttonText, editorUrl }: { buttonText: string; editorUrl?: string } ) => {
  if (!editorUrl) return <></>;

  return (
    <a href={editorUrl} data-turbo="false" data-turbo-frame="_top" className="button is-pulled-right">
      <span className="icon">
        <i className="fas fa-pencil"></i>
      </span>
      <span>
        {buttonText}
      </span>
    </a>
  );
}

const ToggleButton = ({
  expanded,
  onToggle,
}: {
  expanded: boolean;
  onToggle: () => void;
}) => {
  const toggle = () => {
    onToggle();
  };

  const iconClasses = classNames({
    "fas": true,
    "fa-chevron-down": expanded,
    "fa-chevron-right": !expanded,
  });

  return (
    <button className="button is-white" onClick={toggle}>
      <span className="icon">
        <i className={iconClasses}></i>
      </span>
    </button>
  );
}

const CatalogSection = ({
  section,
  setOutOfStockList
}: {
  section: Section;
  setOutOfStockList: React.Dispatch<React.SetStateAction<OutOfStockList | undefined>>;
}) => {
  const [expanded, setExpanded] = useState(true);

  const toggle = () => {
    setExpanded(!expanded);
  }

  const sectionHoursDescription = section.availabilities.map(avail => {
    return `${avail.dayOfWeek()} ${avail.startTime.toLocaleString("en-NZ", { hour: "2-digit", minute: "2-digit" })} to ${avail.endTime.toLocaleString("en-NZ", { hour: "2-digit", minute: "2-digit" })}`;
  }).join("\n");

  const categories = expanded && section.categories.map(category => {
    return <CatalogCategory category={category} key={category.key} setOutOfStockList={setOutOfStockList} />
  });

  return (
    <>
      <div className="block is-flex is-align-items-center">
        <ToggleButton expanded={expanded} onToggle={toggle} />
        <span className="ml-4 title mb-0 mr-2">{section.title}</span>
        <span className="block is-flex is-align-items-center has-tooltip-arrow" style={{ "fontFamily": "monospace" }} data-tooltip={sectionHoursDescription}><HoursIcon className="icon streamline-icon"></HoursIcon></span>
      </div>

      {categories}
    </>
  );
}

const CatalogCategory = ({
  category,
  setOutOfStockList
}: {
  category: Category;
  setOutOfStockList: React.Dispatch<React.SetStateAction<OutOfStockList | undefined>>;
}) => {
  const [expanded, setExpanded] = useState(true);

  const toggle = () => {
    setExpanded(!expanded);
  }

  const children = expanded && (() => {
    const nodes = () => {
      if (category.kind === "leaf") {
        return category.products.map(product => {
          return <CatalogProduct
            product={product}
            key={product.key}
            setOutOfStockList={setOutOfStockList}
          />
        });
      }

      return category.categories.map(category => {
        return <CatalogCategory
          category={category}
          key={category.key}
          setOutOfStockList={setOutOfStockList}
        />
      });
    }
      return (
      <>
    <hr />
    {nodes()}
    </>
    );
  })();

  return (
    <div className="box">
      <div className="is-flex is-align-items-center">
        <ToggleButton expanded={expanded} onToggle={toggle} />
        <span className="ml-4 title is-size-5">{category.title}</span>
      </div>

      {children}
    </div>
  );
}

const CatalogProduct = ({
  product,
  setOutOfStockList,
}: {
  product: Product;
  setOutOfStockList: React.Dispatch<React.SetStateAction<OutOfStockList | undefined>>;
}) => {
  const [outOfStockDialog, setOutOfStockDialog] = useState<boolean>(false);
  const config = useContext(ConfigContext);
  const isOutOfStock = config.outOfStockList.isProductInOutage(product);

  const formatter = new Intl.NumberFormat("en-NZ", { style: "currency", currency: "NZD" });
  const outageEnd = config.outOfStockList.productOnOutageUntil(product);
  const stockClasses = outOfStockDialog ? "modal is-active" : "modal";

  const putBackInStock = async () => {
    updateStock();
  }

  const updateStock = async (status?: string, outOfStockDate?: string) => {
    const metaElement = document.querySelector("meta[name='csrf-token']") as HTMLMetaElement;

    if (metaElement === undefined) {
      throw "Missing CSRF meta tag";
    }

    const csrfToken = metaElement.content;

    const resp = await fetch(config.updateStockUrl, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify(status == "out of stock" ? {
        "sku": product.sku,
        "key": product.key,
        "store_id": config.storeId,
        "status": status,
        "out_of_stock_until": outOfStockDate
      } : {
        "sku": product.sku,
        "key": product.key,
        "store_id": config.storeId
      })
    })

    const json = await resp.json()

    if (json["out_of_stock_list"]) {
      setOutOfStockList(parseOutOfStockList(Temporal.Now.instant(), json["out_of_stock_list"]));
    }
  }

  const button = <span className="icon">
    <i className="fas fa-ellipsis-v"></i>
  </span>;

  const menuContent = <>
    {isOutOfStock ? <div className="dropdown-item" onClick={() => putBackInStock()}>Put back in stock</div> : <div className="dropdown-item" onClick={() => setOutOfStockDialog(true)}>Out of stock...</div>}
  </>;

  const outOfStockOptions = (
    <div className="dropdown is-right actions-dropdown">
      <DropdownButton
        button={button}
        menuContent={menuContent}
        buttonClasses={{ "out-of-stock-button": true }}
      />
      <OutOfStockDialog key={`${product.key}-out-of-stock-dialog`} stockClasses={stockClasses} setOutOfStockDialog={setOutOfStockDialog} product={product} updateStock={updateStock} />
    </div>
  );

  const outOfStockBadge = isOutOfStock && (
    <span className="tag is-dark is-rounded has-tooltip-arrow mx-2" data-tooltip={`Until ${outageEnd?.toLocaleString("en-NZ", { hour: "2-digit", minute: "2-digit", year: 'numeric', month: 'short', day: 'numeric' })}`}>Out of stock</span>
  );

  const image = product.images.length > 0 && (
    <img loading="lazy" src={imageUrl(config.catalogUrl, product.images[0])} />
  );

  const productChildren = () => {
    if (product.kind === "leaf") {
      return [];
    }

    return product.selectionGroups.map(sg => <CatalogSelectionGroup key={sg.key} group={sg} setOutOfStockList={setOutOfStockList} />)
  }

  return (
    <div className="media">
      <div className="media-left">
        <div className="image is-64x64">
          {image}
        </div>
      </div>

      <div className="media-content">
        <div className="content">
          <h1 className="title is-size-6 mb-2">{product.title}</h1>
          {product.description}
        </div>

        {productChildren()}
      </div>

      <div className="media-right is-flex is-align-items-center">
        {outOfStockBadge}
        {config.manualStockManagement && outOfStockOptions}
        <b>{formatter.format(product.price / 100.0)}</b>
      </div>
    </div>
  );
}

const CatalogSelectionGroup = ({
  group,
  setOutOfStockList
}: {
  group: SelectionGroup;
  setOutOfStockList: React.Dispatch<React.SetStateAction<OutOfStockList | undefined>>;
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = useCallback(() => setExpanded(!expanded), [expanded]);
  const iconClasses = classNames({
    "fas": true,
    "fa-chevron-down": expanded,
    "fa-chevron-right": !expanded,
  });

  const groupChildren = group.products.map(p => <CatalogProduct key={p.key} product={p} setOutOfStockList={setOutOfStockList} />);

  return (
    <div className="pl-4 mt-4" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.05)" }}>
      <h3 className="title is-size-6" onClick={toggleExpanded}>
      <span className="icon"><i className={iconClasses} /></span>
        {group.title}
      </h3>


      {expanded && group.description}

      {expanded && groupChildren}
    </div>
  );
}

const imageUrl = (catalogUrl: string, primary: string): string => {
  const url = new URL(`/product_images/${primary}`, new URL(catalogUrl, window.location.href));
  url.searchParams.set("variant", "webp_2x");
  return url.toString();
}

export { Viewer };
