import React, { ChangeEvent, useState, forwardRef, ForwardedRef } from "react";
import { Product } from "src/storefront/types/catalog";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const OutOfStockDialog = ({
  stockClasses,
  setOutOfStockDialog,
  product,
  updateStock
}: {
  stockClasses: string;
  setOutOfStockDialog: React.Dispatch<React.SetStateAction<boolean>>;
  product: Product;
  updateStock: (status?: string, outOfStockDate?: string) => void;
}): JSX.Element => {
  const [selected, setSelected] = useState<"today" | "date" | "permanent" | undefined>(undefined);
  const [dateUntil, setDateUntil] = useState<Date | undefined>(undefined);
  const pastDates = (date: Date) => new Date() < date;

  const endOfDay = () => {
    const today = new Date();
    today.setHours(23, 59, 59);
    return today.toString();
  }

  const outOfStockDate = (): string | undefined => {
    if (selected == "today") return endOfDay();
    if (selected == "date" && dateUntil != undefined) return dateUntil.toString();
  }

  const putOutOfStock = async () => {
    cancelDialog();
    updateStock("out of stock", outOfStockDate());
  }

  const cancelDialog = () => {
    setSelected(undefined);
    setOutOfStockDialog(false);
    setDateUntil(undefined);
  }

  const BasicInputComponent = ({
    inputId,
    label,
  }: {
    inputId: string;
    label: string;
  }): JSX.Element => {
    const optionChange = (e: ChangeEvent) => {
      const target = e.target as HTMLInputElement;

      if (target.value == "today") {
        setSelected("today");
        setDateUntil(undefined);
      }

      if (target.value == "permanent") {
        setSelected("permanent");
        setDateUntil(undefined);

        setOutOfStockDialog(false);
      }
    }

    return <div className="field">
      <input type="radio" checked={selected == inputId} name="out_of_stock" id={inputId} className="radio radio-margin" value={inputId} onChange={(e) => optionChange(e)} />
      <label onClick={(e) => e.preventDefault()} className="radio" htmlFor={inputId}>{label}</label>
    </div>
  }

  const LabeledInput = forwardRef((props, ref) => {
    const shadowProps = props as { onClick(): void };
    const shadowRef = ref as ForwardedRef<HTMLInputElement | null>;

    const optionChange = (e: ChangeEvent) => {
      const target = e.target as HTMLInputElement;

      if (target.value == "date") {
        setSelected("date");
      }
    }

    return (<div className="field">
      <input
        type="radio"
        ref={shadowRef}
        checked={selected == 'date'}
        onClick={shadowProps.onClick}
        onChange={(e) => optionChange(e)}
        name="out_of_stock"
        id="date"
        className="radio radio-margin"
        value="date"
      />
      <label className="radio" htmlFor="date">Until a specific date...</label>
    </div>);
  });

  LabeledInput.displayName = 'Labeled Input';

  const handleDate = (date: Date | null) => {
    if (date) {
      date.setHours(23, 59, 59);
      setDateUntil(date);
    }
  }

  return (
    <>
      <div className={stockClasses}>
      <div className="modal-background"></div>
      <div className="modal-card" style={{ overflow: 'visible' }}>
        <header className="modal-card-head">
          <div className="modal-card-title">{`How long should ${product.title} be out of stock?`}</div>
          <button className="delete" onClick={cancelDialog} aria-label="close"></button>
        </header>

        <section className="modal-card-body">
          <BasicInputComponent inputId={"today"} label={"End of the day - Until 12 am"} />

          <div className="field">
            <DatePicker
              selected={dateUntil}
              filterDate={pastDates}
              onChange={(d) => handleDate(d)}
              popperPlacement="right-end"
              customInput={<LabeledInput />}
            />

            {dateUntil && <div>{`${dateUntil.toLocaleDateString()}`}</div>}
          </div>

          <BasicInputComponent inputId={"permanent"} label={"Item will be permanently out of stock"} />
        </section>

        <footer className="modal-card-foot is-justify-content-space-between">
          <button className="button" onClick={cancelDialog}>Cancel</button>
          <button onClick={putOutOfStock} disabled={selected == undefined} className="button is-primary">Save</button>
        </footer>
      </div>
      </div>

      {selected == "permanent" && <PermanentRemovalDialog key={`${product.key}-permanent-removal-dialog`} selected={selected} productTitle={product.title} setSelected={setSelected} updateStock={updateStock} />}
    </>);
};

export const PermanentRemovalDialog = ({
  productTitle,
  selected,
  setSelected,
  updateStock
}: {
  selected: "today" | "date" | "permanent" | undefined;
  productTitle: string;
  setSelected: React.Dispatch<React.SetStateAction<"today" | "date" | "permanent" | undefined>>;
  updateStock: (status?: string) => void;
}): JSX.Element => {
  const permanentRemovalClasses = selected == "permanent" ? "modal is-active" : "modal";

  const cancelDialog = () => {
    setSelected(undefined);
  }

  const putOutOfStock = async () => {
    setSelected(undefined);
    updateStock("out of stock");
  }

  return <div className="dropdown is-right actions-dropdown">
    <div className={permanentRemovalClasses}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <div className="modal-card-title">{`Remove this item?`}</div>
          <button onClick={cancelDialog} className="delete" aria-label="close"></button>
        </header>

        <section className="modal-card-body">
          <div>{productTitle} will be permanently made out of stock</div>
        </section>

        <footer className="modal-card-foot is-justify-content-space-between">
          <button onClick={cancelDialog} className="button">Cancel</button>
          <button onClick={putOutOfStock} className="button is-primary">Confirm</button>
        </footer>
      </div>
    </div>
  </div>;
};
